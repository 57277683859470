import { Config } from "@greeter/config";
import { doNothing } from "@greeter/util";
import { sirene } from "@greeter/log";
import { IAuthentication } from "@greeter/auth";
import { LocalFeatureFlags, FeatureFlag } from "@greeter/local-feature-flags";

import { logger, warner } from "@greeter/log";
import { z } from "zod";

const name = "[ProxyBase]";
const log = logger(name);
const warn = warner(name);
const error = sirene(name);

function parseContentDisposition(contentDisposition?: string | null) {
  if (!contentDisposition) return [];

  const disp = contentDisposition
    .split(";")
    .map((s) => s.trim())
    .map((kw: string) => {
      const [k, v] = kw.split("=");
      return [k, v];
    });

  return disp;
}

const ProblemDetails = z.object({
  title: z.string(),
  status: z.number(),
  detail: z.string(),
  data: z.object({
    errorCode: z.string(),
    reasons: z.array(z.string()),
  }),
});
type ProblemDetails = z.infer<typeof ProblemDetails>;

/**
 * Used for easily testing and quickly returning error details.
 */
export function NotFoundProblemDetails(): ProblemDetails {
  return {
    title: "Entity not found",
    status: 404,
    detail: "Could not find entity",
    data: {
      errorCode: "NOT_FOUND",
      reasons: ["The entity does not exist"],
    },
  };
}

export class ApiError extends Error {
  details: ProblemDetails;

  constructor(error: ProblemDetails) {
    super();
    this.details = error;
  }
}

type WriteOptions = {
  authorize?: boolean;
};

export type PostArgs = WriteOptions & {
  headers?: HeadersInit;
  body?: BodyInit;
};

type PostOptions = WriteOptions;
type PatchOptions = WriteOptions;

export enum ApiVersions {
  None,
  V1 = "v1",
  V2 = "v2",
}

export abstract class BaseProxy {
  config: Config;
  authentication: IAuthentication;
  apiVersion = ApiVersions.V2;

  constructor(config: Config, authentication: IAuthentication) {
    this.config = config;
    this.authentication = authentication;
  }

  protected getBaseUrl() {
    return this.config.apiUrl;
  }

  protected abstract getUrl(): URL;

  protected async post(url: string, args?: PostArgs): Promise<Response> {
    return await fetch(url, {
      method: "POST",
      headers: args?.headers,
      body: args?.body,
    });
  }

  protected async postWithAuth(
    url: string,
    body?: BodyInit,
    headerVisitor: (headers: Headers) => void = doNothing
  ): Promise<any> {
    const headers = await this.createHeadersWithAuth();

    headerVisitor(headers);

    const response = await this.post(url, { body, headers });
    if (!response.ok) {
      throw new Error(
        `Response returned: ${
          response.status
        }; with message: ${await response.text()}`
      );
    }

    return await this.tryDeserializingJson(response);
  }

  protected async authPost(url: string, body?: BodyInit) {
    return await this.post(url, {
      body,
      headers: await this.createHeadersWithAuth(),
    });
  }

  protected async postWithoutResult(
    url: string,
    body?: BodyInit
  ): Promise<void> {
    const headers = await this.createHeadersWithAuth();
    const response = await fetch(url, {
      method: "POST",
      headers: headers,
      body: body,
    });

    if (!response.ok) {
      throw new Error("Response was " + response.status);
    }
  }

  protected async getWithAuth(
    url: string,
    config?: (init: RequestInit) => RequestInit
  ): Promise<any> {
    const headers = await this.createHeadersWithAuth();
    const init: RequestInit = {
      method: "GET",
      headers: headers,
    };

    if (config) config(init);

    const response = await fetch(url, init);

    return await this.tryDeserializingJson(response);
  }

  protected async getBlobWithAuth(
    url: string,
    config?: (init: RequestInit) => RequestInit
  ): Promise<any> {
    const headers = await this.createHeadersWithAuth();
    const init: RequestInit = {
      method: "GET",
      headers: headers,
    };

    if (config) config(init);

    const response = await fetch(url, init);

    return await response.blob();
  }

  protected async getFileWithAuth(
    url: string,
    config?: (init: RequestInit) => RequestInit
  ): Promise<any> {
    const headers = await this.createHeadersWithAuth();
    const init: RequestInit = {
      method: "GET",
      headers: headers,
    };

    if (config) config(init);

    const response = await fetch(url, init);

    const contentDisposition = parseContentDisposition(
      response.headers.get("Content-Disposition")
    );

    const [_, name] = contentDisposition.find(([k, v]) => {
      return k === "filename";
    }) ?? ["filename", "file.pdf"];

    const type = response.headers.get("Content-Type") ?? "application/pdf";

    return new File([await response.blob()], name, {
      type: type,
    });
  }

  protected async get(url: string, body?: RequestInit): Promise<any> {
    const response = await fetch(url, {
      method: "GET",
      headers: this.createHeaders(),
      ...body,
    });
    return await this.tryDeserializingJson(response);
  }

  protected async delete(url: string, body?: BodyInit): Promise<Response> {
    const response = await fetch(url, {
      method: "DELETE",
      headers: await this.createHeadersWithAuth(),
      body,
    });

    return response;
  }

  protected async patch(
    url: string,
    body?: BodyInit,
    options: PatchOptions = { authorize: true }
  ): Promise<Response> {
    const headers = options.authorize
      ? await this.createHeadersWithAuth()
      : this.createHeaders();
    const response = await fetch(url, {
      method: "PATCH",
      headers: headers,
      body: body,
    });

    return response;
  }

  protected async patchAndDeserialize(
    url: string,
    body?: BodyInit
  ): Promise<any> {
    return await this.tryDeserializingJson(await this.patch(url, body));
  }

  /**
   * @throws {ApiError} if the status code is in the range of 400-600
   */
  protected async tryDeserializingJson(response: Response) {
    try {
      const r = await response.clone().json();

      if (response.status >= 400 && response.status <= 600) {
        throw new ApiError(ProblemDetails.parse(r));
      }

      return r;
    } catch (exception) {
      const text = await response.clone().text();
      log("Failed request", text);
      error(exception);
      throw exception;
    }
  }

  protected async createHeadersWithAuth(): Promise<Headers> {
    return await this.addAuth(this.createHeaders());
  }

  protected createHeaders(): Headers {
    const headers = new Headers();
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Content-Type", "application/json");
    // TODO: Should be removed in the future and default
    if (this.apiVersion !== ApiVersions.None) {
      headers.set("X-Api-Version", this.apiVersion);
    }
    return headers;
  }

  private async addAuth(headers: Headers) {
    try {
      const token = await this.authentication.getAuthToken();
      const bearerString = `Bearer ${token}`;
      headers.set("Authorization", bearerString);

      return headers;
    } catch {
      warn(
        "Be advised, fetching id token failed, user is probably not logged in. Could not set auth headers."
      );

      return headers;
    }
  }
}

/**
 * TODO: specify this
 */
export class NetworkError extends Error {}
export class ParseError extends Error {
  constructor(reason: string, public innerError?: Error) {
    super(reason);
  }
}
